<template>
  <v-container fluid>
    <v-radio-group row v-model="vorlage">
      <v-radio label="PDF ohne Hinterdrund zum Druck auf fertiges Urkundenpapier" :value="false" />
      <v-radio label="PDF inklusive Hinterdrund" :value="true" />
    </v-radio-group>
    <v-btn block @click="download()">Alle Urkunden</v-btn>

    <v-data-table
      :headers="[
        { text: 'Platz', value: 'place', sortable: false },
        { text: 'Verein', value: 'club', sortable: false },
        { text: 'Turner', value: 'athletes', sortable: false },
        { text: 'Gesamt', value: 'final', sortable: false },
      ]"
      :items="items"
      :items-per-page="-1"
      hide-default-footer
      @click:row="download"
    >
      <template #item.final="{item}">
        {{ item.final | float2 }}
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { useCalc } from '@/views/components/stb_ltf_2024/plugins/calc'
import Vue from 'vue'
import { usePdf } from '@/views/components/stb_ltf_2024/plugins/pdf'

export default {
  name: 'urkunden',

  setup (_, context) {
    return {
      ...useCalc(),
      ...usePdf(context)
    }
  },

  props: {
    id: {
      type: String,
      required: true
    },
    e: {
      type: Object,
      required: true
    },
    r: {
      type: Array,
      required: true
    }
  },

  data: () => ({
    vorlage: false
  }),

  computed: {
    items () {
      return this.e.teams?.map(t => ({
        ...t,
        club: t.club.name,
        athletes: t.athletes.map(a => Vue.filter('person')(a)).join('; '),
        final: this.mannschaftergebnis(this.e, this.r, this.teamid(t))
      })).sort((a, b) => a.final > b.final ? -1 : 1).map((t, _, arr) => ({
        ...t,
        place: arr.filter(t2 => t2.final > t.final).length + 1
      }))
    }
  },

  methods: {
    async download (item) {
      await this.urkunde(this.e, this.r, this.teamid(item) || null, this.vorlage)
    }
  }
}
</script>

<style scoped>

</style>
